.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cr-build-fail\:oom { background: #db3026; }
.cr-error { background: #d77026; }
.cr-test-pass { background: #62a156; }
.cc-test-pass { background: #62a156; }
.cc-tag-ok { background: #6c32b8; }
.cc-tag-failed { background: #116a6d; }
.cc-error { background: #d77026; }
.cc-changed { background: repeating-linear-gradient(-45deg, #5630db, #5630db 15px, #5d3dcf 15px, #5d3dcf 30px); }
.cc-spurious-fixed { background: repeating-linear-gradient(-45deg, #5630db, #5630db 15px, #5d3dcf 15px, #5d3dcf 30px); }
.cc-spurious-regressed { background: repeating-linear-gradient(-45deg, #db3026, #db3026 15px, #d5433b 15px, #d5433b 30px); }